import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import './index.scss';

type ButtonProps = {
  onClick?: Function;
  type?: 'button' | 'submit' | 'reset';
  color?: 'green' | 'yellow' | 'red';
  component?: 'button' | 'link';
  className?: string;
  disabled?: boolean;
  children: ReactNode;
};

export const Button = ({
  onClick,
  type,
  color,
  className,
  disabled,
  component,
  children,
}: ButtonProps) => {
  const [visited, setVisited] = useState(false);

  return (
    <button
      type={type ? type : 'button'}
      className={
        component === 'link'
          ? classNames('link', visited && 'visited')
          : classNames('button', color, className)
      }
      onClick={(event) => {
        setVisited(true);
        onClick && onClick(event);
      }}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
