import { Star, StarFill, StarHalf } from '@emotion-icons/bootstrap';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Button } from '..';
import './index.scss';

type EvaluationProps = {
  userAnswer: string;
  answer: string;
  committed: boolean;
  onEvaluated: (score: number) => void;
};

export const Evaluation = ({
  userAnswer,
  answer,
  committed,
  onEvaluated,
}: EvaluationProps) => {
  const [score, setScore] = useState(0);

  function evaluate(points: number) {
    setScore(points);
    onEvaluated(points);
  }

  function getClassName() {
    if (score === 0) return 'incorrect';
    if (score === 0.5) return 'half';
    return 'correct';
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <p className="answer">
        <strong>Du svarte: </strong>
        {userAnswer}
      </p>
      <p className="answer">
        <strong>Riktig svar: </strong>
        <span
          className="answer-description"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </p>

      {!committed ? (
        <div className="scores">
          <Button onClick={() => evaluate(1)}>Riktig</Button>
          <Button onClick={() => evaluate(0.5)}>Nesten riktig</Button>
          <Button onClick={() => evaluate(0)}>Galt</Button>
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={classNames('points', getClassName())}
        >
          {score === 0 && <Star size="32" />}
          {score === 0.5 && <StarHalf size="32" />}
          {score === 1 && <StarFill size="32" />}

          <p>{score} poeng</p>
        </motion.div>
      )}
    </motion.div>
  );
};
