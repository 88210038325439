import { motion } from 'framer-motion';
import { useState } from 'react';
import { Button } from '..';
import './index.scss';

type AnswerProps = {
  handleAnswer: Function;
  disabled: boolean;
};

export const Answer = ({ handleAnswer, disabled }: AnswerProps) => {
  const [answer, setAnswer] = useState('');

  return (
    <motion.form
      onSubmit={(event) => handleAnswer(event, answer)}
      className="answerInput"
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <input
        type="text"
        value={answer}
        onChange={(event) => setAnswer(event.target.value)}
        disabled={disabled}
      />
      <Button type="submit" disabled={disabled}>
        Jeg er klar. Vis svar!
      </Button>
    </motion.form>
  );
};
