import classNames from 'classnames';
import React, { useState } from 'react';
import { Answer, Evaluation, Question } from '..';
import './index.scss';

export type AdjacencyPairData = {
  question: string;
  answer: string;
  imageUrl?: string;
  imageAltText?: string;
};

type AdjacencyPairProps = {
  onReview: (currentScore: number) => void;
  data: AdjacencyPairData;
  index: number;
  disabled: boolean;
};

export const AdjacencyPair = ({
  onReview,
  data,
  index,
  disabled,
}: AdjacencyPairProps) => {
  const [userAnswer, setUserAnswer] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [committed, setCommitted] = useState(false);

  function handleAnswer(
    event: React.FormEvent<HTMLFormElement>,
    answer: string
  ) {
    setSubmitted(true);
    setUserAnswer(answer);
    event.preventDefault();
  }

  function assignScore(score: number) {
    setScore(score);
    setCommitted(true);
    onReview(score);
  }

  function getColor() {
    if (committed) {
      switch (score) {
        case 1:
          return 'green';
        case 0.5:
          return 'yellow';
        case 0:
          return 'red';
      }
    }
  }

  return (
    <>
      {index !== 0 && <div className="divider" />}
      <div className={classNames('pair', getColor())} key={index}>
        {data.imageUrl && <img src={data.imageUrl} alt={data.imageAltText} />}
        <Question question={data.question} index={index} />

        {!submitted && (
          <Answer handleAnswer={handleAnswer} disabled={disabled} />
        )}

        {submitted && (
          <Evaluation
            userAnswer={userAnswer}
            answer={data.answer}
            committed={committed}
            onEvaluated={(score) => {
              assignScore(score);
            }}
          />
        )}
      </div>
    </>
  );
};
