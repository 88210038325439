import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { AdjacencyPair, AdjacencyPairData, Button, ScoreBoard } from '..';
import { getQuiz } from '../../queries/getQuiz';
import './styles.scss';

export type QuizData = {
  adjacencyPairs: AdjacencyPairData[];
  authors: string;
};

export const Quiz = () => {
  const [completed, setCompleted] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const [quiz, setQuiz] = useLocalStorage<QuizData>('quiz');
  const [score, setScore] = useLocalStorage<number>('score');

  useEffect(() => setScore(0), [setScore]);

  const { slug } = useParams();

  const handleReview = (currentScore: number) => {
    setCompleted((completed) => completed + 1);
    setScore((score || 0) + currentScore);
  };

  const submit = () => {
    setSubmitted(true);
  };

  const { isLoading, isError, data } = useQuery<QuizData>('quiz', () =>
    getQuiz(slug)
  );

  useEffect(() => setQuiz(data), [data, setQuiz]);

  if (isLoading || !data) {
    return <span>Laster inn...</span>;
  } else if (isError) {
    return <span>Fant ikke kviss</span>;
  }

  return (
    <div className="app">
      {data.adjacencyPairs.map((pair, index) => (
        <AdjacencyPair
          onReview={handleReview}
          data={pair}
          index={index}
          key={index}
          disabled={submitted}
        />
      ))}

      <div className="footer">
        {!submitted ? (
          <motion.div initial={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="status">
              <strong>{score}</strong>
              <span>{` poeng (${completed} av ${quiz?.adjacencyPairs.length} fullført)`}</span>
            </div>

            <Button onClick={submit}>Klikk for vurdering</Button>
          </motion.div>
        ) : (
          <ScoreBoard />
        )}
      </div>
    </div>
  );
};
