import level1 from '../assets/sounds/level1.mp3';
import level2 from '../assets/sounds/level2.mp3';
import level3 from '../assets/sounds/level3.mp3';
import level4 from '../assets/sounds/level4.mp3';
import level5 from '../assets/sounds/level5.mp3';

type LevelType = {
  maxPercentage: number;
  description: string;
  sound: '.*mp3';
  soundDescription: string;
  socialMediaDescription: Function;
};

const osloKlarinettkor = 'Fanfaren er levert av Oslo klarinettkor';

const LEVELS: LevelType[] = [
  {
    maxPercentage: 0.15,
    description: 'Kanskje du er flink til å tegne?',
    sound: level1,
    soundDescription: osloKlarinettkor,
    socialMediaDescription: (score: number, max: number) =>
      `Jeg fikk ${score} av ${max} på Morgenbladet-kvissen. Ja-ja. Jeg tar de poengene jeg får. Noen som vil gå en tur?`,
  },
  {
    maxPercentage: 0.35,
    description: 'Hvordan synes du det selv gikk?',
    sound: level2,
    soundDescription: osloKlarinettkor,
    socialMediaDescription: (score: number, max: number) =>
      `Jeg fikk ${score} av ${max}, men lærte i det minste mye nytt på Morgenbladet-kvissen denne uken!`,
  },
  {
    maxPercentage: 0.6,
    description: 'Bra! Men du leser tross alt Morgenbladet.',
    sound: level3,
    soundDescription: osloKlarinettkor,
    socialMediaDescription: (score: number, max: number) =>
      `Det kan kanskje virke midt på treet å få ${score} av ${max}, men Morgenbladet sier at det er over snittet!`,
  },
  {
    maxPercentage: 0.85,
    description: 'Tenk om du hadde vært like flink ellers i livet.',
    sound: level4,
    soundDescription: osloKlarinettkor,
    socialMediaDescription: (score: number, max: number) =>
      `Jeg fikk fanfare og ${score} av ${max} poeng i Morgenblad-kvissen. Nå er jeg stolt av meg selv. Er du stolt av deg selv?`,
  },
  {
    maxPercentage: 1.0,
    description: 'Utmerket! Du brukte vel ikke internett?',
    sound: level5,
    soundDescription:
      'Fanfaren er kvissmesternes midi-tolkning av «Jeg er havren» for fullt symfoniorkester.',
    socialMediaDescription: (score: number, max: number) =>
      `Jeg fikk ${score} av ${max} poeng og den største fanfaren i Morgenblad-kvissen! Jeg har (mest sannsynlig) jukset.`,
  },
];

export function getLevel(score: number, max: number): LevelType {
  const percentage = score / max;

  const level = LEVELS.find((level) => level.maxPercentage >= percentage);

  if (typeof level !== 'undefined') return level;

  return LEVELS[0];
}
