import { motion } from 'framer-motion';
import { useLocalStorage } from 'react-use';
import { QuizData, SocialMedia } from '..';
import { getLevel } from '../../queries/getLevel';
import { Description } from './Description';
import './styles.scss';

export const ScoreBoard = () => {
  const [quiz] = useLocalStorage<QuizData>('quiz');
  const [score] = useLocalStorage<number>('score');
  const max = quiz?.adjacencyPairs.length || 0;
  const level = getLevel(score || 0, max);

  return (
    <motion.div
      className="scoreboard"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <h2>POENGSKÅR</h2>
      {typeof score !== 'number' ? (
        <p>Noe gikk galt</p>
      ) : (
        <>
          <Description
            score={score}
            max={max}
            feedback={level.description}
            sound={level.sound}
          />
          <SocialMedia description={level.socialMediaDescription(score, max)} />
          <i>{level.soundDescription}</i>
        </>
      )}
    </motion.div>
  );
};
