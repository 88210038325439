import './index.scss';

type QuestionProps = {
  question: string;
  index: number;
};

export const Question = ({ question, index }: QuestionProps) => (
  <p className="question">
    <span className="number">{index + 1}: </span>
    <span
      className="question-description"
      dangerouslySetInnerHTML={{ __html: question }}
    />
  </p>
);
