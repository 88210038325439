import { useState } from 'react';
import useSound from 'use-sound';
import { Button } from '../button/Button';

type DescriptionProps = {
  score: number;
  max: number;
  feedback: string;
  sound: any;
};

export const Description = ({
  score,
  max,
  feedback,
  sound,
}: DescriptionProps) => {
  const [play, exposedData] = useSound(sound);
  const [playing, setPlaying] = useState(false);

  function toggleSound() {
    playing ? exposedData.stop() : play();
    setPlaying(!playing);
  }

  return (
    <>
      <strong>
        Du fikk {score} av {max} poeng og har gjort deg fortjent til{' '}
        <Button onClick={toggleSound} component="link">
          denne
        </Button>{' '}
        fanfaren.
      </strong>
      <p>{feedback}</p>
    </>
  );
};
