import { QuoteLeft, QuoteRight } from '@emotion-icons/fa-solid';
import './index.scss';
import { Facebook, LinkedIn, Reddit, Twitter } from './Platforms';

type SocialMediaProps = {
  description: string;
};

function getParentUrl() {
  const url =
    window.location !== window.parent.location
      ? document.referrer
      : document.location.href;

  return url.substring(0, url.lastIndexOf('/') + 1);
}

export const SocialMedia = ({ description }: SocialMediaProps) => {
  const url = getParentUrl();

  return (
    <div className="social-media-container">
      <h3>Del resultatet ditt:</h3>
      <div className="quote">
        <QuoteLeft size="24" className="quote-left" />
        <QuoteRight size="24" className="quote-right" />
        <p>{description}</p>
      </div>

      <div className="share-buttons">
        <Facebook description={description} url={url} />
        <Twitter description={description} url={url} />
        <Reddit description={description} url={url} />
        <LinkedIn description={description} url={url} />
      </div>
    </div>
  );
};
