import { useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

export function useResizeIframe(origins: string[]) {
  const [iframeMessage, setIframeMessage] = useState<MessageEvent>();
  const { ref, height = 0 } = useResizeObserver<HTMLDivElement>();

  useEffect(() => {
    window.addEventListener('message', (event: MessageEvent) => {
      if (!origins.includes(event.origin)) {
        return;
      }

      setIframeMessage(event);
    });
  }, [origins]);

  useEffect(() => {
    if (iframeMessage?.source) {
      iframeMessage.source.postMessage(
        JSON.stringify({ event: 'resize', height }),
        { targetOrigin: iframeMessage.origin }
      );
    }
  }, [height, iframeMessage]);

  return ref;
}
