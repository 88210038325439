import { AnimatePresence } from 'framer-motion';
import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useResizeIframe } from '../../hooks/useResizeIframe';
import { Quiz } from '../quiz/Quiz';
import './styles.scss';

declare global {
  interface Window {
    env: any;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  const ref = useResizeIframe([
    'https://dev.morgenbladet.no',
    'https://staging.morgenbladet.no',
    'https://testing.morgenbladet.no',
    'https://www.morgenbladet.no',
    'https://sandbox.mentormedier.arcpublishing.com',
    'https://mentormedier.arcpublishing.com',
  ]);

  return (
    <div ref={ref}>
      <div className="wrapper">
        <BrowserRouter>
          <AnimatePresence>
            <QueryClientProvider client={queryClient}>
              <Suspense fallback="">
                <Routes>
                  <Route path="/:slug" element={<Quiz />} />
                  {/* <Route path="/:slug/scoreBoard" element={<ScoreBoard />} /> */}
                </Routes>
              </Suspense>
            </QueryClientProvider>
          </AnimatePresence>
        </BrowserRouter>
      </div>
    </div>
  );
};
