import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

type PlatformProps = {
  description: string;
  url: string;
};

const iconSize = 40;

export const Facebook = ({ description, url }: PlatformProps) => (
  <FacebookShareButton quote={description} url={url} hashtag="morgenbladet">
    <FacebookIcon size={iconSize} round={true} />
  </FacebookShareButton>
);

export const Twitter = ({ description, url }: PlatformProps) => (
  <TwitterShareButton title={description} url={url} hashtags={['morgenbladet']}>
    <TwitterIcon size={iconSize} round={true} />
  </TwitterShareButton>
);

export const Reddit = ({ description, url }: PlatformProps) => (
  <RedditShareButton title={description} url={url}>
    <RedditIcon size={iconSize} round={true} />
  </RedditShareButton>
);

export const LinkedIn = ({ description, url }: PlatformProps) => (
  <LinkedinShareButton title={description} url={url}>
    <LinkedinIcon size={iconSize} round={true} />
  </LinkedinShareButton>
);
